<template>
  <div class="container">
    <div class="navMenu">
      <div class="nav_main">
        <div class="nav_ul">
          <el-menu
            :default-active="$route.path"
            class="el-menu-demo"
            mode="horizontal"
            menu-trigger="hover"
            router
            @select="handleSelect"
            background-color="#181513"
            text-color="#fff"
            active-text-color="#2AA0DC"
          >
            <el-menu-item index="/home">首页</el-menu-item>
            <el-submenu index="2" popper-class="item_hover">
              <template slot="title">解决方案</template>
              <!-- <el-menu-item disabled
                            style="color:#fff;opacity: 1">
                <template slot="title">尚盈车联</template>
              </el-menu-item>
              <el-menu-item style="display:flex;padding:0;margin-bottom:20px;align-items:center">
                <el-menu-item style="color:#fff;flex:1;text-align:center"
                              index="/hxb">好享泊</el-menu-item>
                <div style="border-right:1px solid #fff;height:16px;"></div>
                <el-menu-item style="color:#fff;flex:1;text-align:center"
                              index="/hxj">好享驾</el-menu-item>
              </el-menu-item> -->
              <el-menu-item style="color: #fff" index="/hxb"
                >尚盈车联</el-menu-item
              >

              <el-menu-item style="color: #fff" index="/lxxp"
                >乐享小铺</el-menu-item
              >
              <el-menu-item style="color: #fff" index="/symp"
                >尚盈名品</el-menu-item
              >
            </el-submenu>
            <!-- <el-menu-item index="/news">新闻中心</el-menu-item> -->
            <el-menu-item index="/success-demo">成功案例</el-menu-item>
            <el-menu-item index="/business-cooperation">招商合作</el-menu-item>
            <el-submenu index="6">
              <template slot="title">关于我们</template>
              <el-menu-item index="/company" style="color: #fff"
                >公司介绍</el-menu-item
              >
              <el-menu-item index="/contact-us" style="color: #fff"
                >联系我们</el-menu-item
              >
            </el-submenu>
          </el-menu>
        </div>
        <div class="goTop" @click="goTop">TOP</div>
      </div>
    </div>
    <div class="main">
      <ul class="main_ul">
        <li class="ul_item contact">
          <div class="title">联系方式</div>
          <div class="line">
            <div class="line1"></div>
          </div>
          <div class="text_con">
            <div class="title">
              <div class="logo_pic"></div>
              <div class="title_info">江苏尚盈颐期信息网络有限公司</div>
            </div>
          </div>
          <div class="content">
            <ul class="content_ul">
              <li class="content_ul_item">地址: 常州武进区牛溪路19号盈立方</li>
              <li class="content_ul_item">邮箱: service@shangyqy.cn</li>
              <li class="content_ul_item">电话: 400-9988-406</li>
              <li class="content_ul_item flex_li">
                <span class="fontsz_12">备案号: </span>
                <!-- <span class="fontsz_12" style="padding-left: 5px">
                  苏ICP备20015085号</span
                > -->
                <div>
                  <p class="fontsz_12" style="padding-left: 5px">
                    苏ICP备20015085号
                  </p>
                  <!-- <p class="fontsz_12" style="padding-left: 5px">
                    苏ICP备20015085号-2
                  </p>
                  <p class="fontsz_12" style="padding-left: 5px">
                    苏ICP备20015085号-3
                  </p> -->
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="ul_item Contact_us">
          <div class="title">联系我们</div>
          <div class="form_">
            <input class="input_item" type="text" placeholder="姓名:" />
            <input class="input_item" type="text" placeholder="电话:" />
            <textarea class="text_area" placeholder="留言:"></textarea>
            <el-button class="btn" type="primary">提交信息</el-button>
          </div>
        </li>
        <li class="ul_item weChat_Qrcode">
          <div class="title">官方微信</div>
          <div class="line">
            <div class="line1"></div>
          </div>
          <div class="qr_scan">扫码关注</div>
          <ul class="qr_pic">
            <li class="qr_pic_item">
              <img class="item_pic" src="@/assets/images/syqy.jpg" alt="" />
              <p>尚盈期颐</p>
              <p>公众号</p>
            </li>
            <li class="qr_pic_item">
              <img class="item_pic" src="@/assets/images/sylsh.jpg" alt="" />
              <p>尚盈乐生活</p>
              <p>公众号</p>
            </li>
            <li class="qr_pic_item">
              <img class="item_pic" src="@/assets/images/sycl.jpg" alt="" />
              <p>尚盈车联</p>
              <p>公众号</p>
            </li>
          </ul>
        </li>
      </ul>
      <div class="bottom_text">
        <div class="left_text">江苏尚盈期颐信息网络有限公司</div>
        <div class="right_text">
          <a class="beian" target="_blank" href="https://beian.miit.gov.cn"
            >苏ICP备20015085号</a
          >
          <!-- <a class="beian" target="_blank" href="https://beian.miit.gov.cn"
            >苏ICP备18004255号-2,苏ICP备20015085号-2,苏ICP备20015085号-3</a
          > -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot-form",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    // 选中nav
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    // 去顶部
    goTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background-color: #000000;
  .navMenu {
    width: 100%;
    background-color: #181513;
    height: 50px;
    .nav_main {
      width: 960px;
      margin: 0 auto;
      height: 50px;
      justify-content: space-between;
      display: flex;
      .nav_ul {
        flex: 1;
        /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
          height: 50px;
          line-height: 50px;
        }
        /deep/ .el-menu--horizontal > .el-menu-item {
          height: 50px;
          line-height: 50px;
        }
        /deep/ .el-menu.el-menu--horizontal {
          border: none;
        }
        /deep/ .el-menu--horizontal {
          display: flex;
          justify-content: space-between;
        }
      }
      .goTop {
        width: 66px;
        background-color: #2aa0dc;
        color: #fff;
        line-height: 50px;
        text-align: center;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
  .main {
    width: 960px;
    margin: 0 auto;
    padding-bottom: 60px;
    .main_ul {
      display: flex;
      justify-content: space-between;
      min-height: 427px;
      border-bottom: 1px solid #333333;
      .ul_item {
        flex: 1;
        margin-right: 60px;
        padding-top: 63px;
        &:last-child {
          margin-right: 0px;
        }
        .title {
          color: #959595;
          font-size: 16px;
          margin-bottom: 36px;
        }
        .line {
          width: 100%;
          border-bottom: 1px solid #333333;
          .line1 {
            width: 30px;
            border-bottom: 2px solid #c4c4c4;
          }
        }
        .text_con {
          margin-top: 30px;
          .title {
            display: flex;
            align-items: center;
            .logo_pic {
              width: 20px;
              height: 26px;
              background: url("../../../assets/images/foot_small_logo.png")
                no-repeat center;
              background-size: 100% 100%;
            }
            .title_info {
              flex: 1;
              margin-left: 10px;
              color: #2aa0dc;
              font-size: 12px;
            }
          }
        }
        .content {
          padding-left: 30px;
          color: #959595;
          .content_ul {
            .content_ul_item {
              font-size: 12px;
              margin-top: 15px;
              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
        .form_ {
          font-size: 12px;
          .input_item {
            width: 100%;
            height: 40px;
            background-color: #000;
            outline: none;
            border: 1px solid #333333;
            margin-bottom: 14px;
            padding: 0 15px;
            color: #fff;
          }
          .text_area {
            width: 100%;
            height: 100px;
            background-color: #000;
            color: #fff;
            padding: 10px 15px;
            resize: none;
            border: 1px solid #333333;
          }
          .btn {
            width: 80%;
            margin-top: 30px;
            margin-left: 10%;
          }
        }
      }
      .weChat_Qrcode {
        .qr_scan {
          color: #959595;
          margin-top: 30px;
          margin-bottom: 25px;
          font-size: 12px;
        }
        .qr_pic {
          display: flex;
          justify-content: space-between;
          text-align: center;
          .item_pic {
            width: 84px;
            height: 84px;
            background-color: #fff;
            margin-bottom: 15px;
          }
          p {
            color: #c4c4c4;
            margin-top: 10px;
            font-size: 12px;
          }
        }
      }
    }
    .bottom_text {
      display: flex;
      justify-content: space-between;
      color: #666666;
      margin-top: 30px;
      div {
        font-size: 12px;
      }
    }
  }
  .beian {
    color: #666666;
    text-decoration: none;
    font-size: 12px;
  }
  .fontsz_12 {
    font-size: 12px;
  }
  .flex_li {
    display: flex;
    justify-content: flex-start;
  }
}
</style>
