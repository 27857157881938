<template>
  <div class="container">
    <el-carousel :interval="4000"
                 type="card"
                 height="400px">
      <el-carousel-item v-for="item in imgList"
                        :key="item.src">
        <img :src="item.src"
             alt="">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'my-swiper',
  props: ["imgList"],
  data () {
    return {

    };
  },
  created () {

  },
  mounted () {
    console.log(this.imgList);
  },
  methods: {

  },
}
</script>

<style lang='scss' scoped>
.container {
  width: 100%;
}
img {
  width: 100%;
  height: 100%;
}
</style>