<template>
  <div class="container">
    <div class="main">
      <div class="logo_pic"></div>
      <div class="line"></div>
      <el-menu :default-active="$route.path"
               class="el-menu-demo"
               mode="horizontal"
               menu-trigger='click'
               router
               unique-opened
               @select="handleSelect"
               text-color="#000"
               active-text-color="#2AA0DC"
               style="color: #c4c4c4;">
        <el-menu-item index="/home">首页</el-menu-item>
        <el-submenu index=2>
          <template slot="title">解决方案</template>
          <!-- <el-menu-item disabled
                        style="color:#000;opacity: 1">
            <template slot="title">尚盈车联</template>
          </el-menu-item>
          <el-menu-item style="display:flex;padding:0;margin-bottom:20px;align-items:center">
            <el-menu-item index="/hxb"
                          style="color:#909399;flex:1;text-align:center">好享泊</el-menu-item>
            <div style="border-right:1px solid #000;height:16px;"></div>
            <el-menu-item index="/hxj"
                          style="color:#909399;flex:1;text-align:center">好享驾</el-menu-item>
          </el-menu-item> -->

          <el-menu-item style="color:#909399;"
                        index="/hxb">尚盈车联</el-menu-item>
          <el-menu-item style="color:#909399;"
                        index="/lxxp">乐享小铺</el-menu-item>
          <el-menu-item style="color:#909399;"
                        index="/symp">尚盈名品</el-menu-item>
        </el-submenu>
        <!-- <el-menu-item index="/news">新闻中心</el-menu-item> -->
        <el-menu-item index="/success-demo">成功案例</el-menu-item>
        <el-menu-item index="/business-cooperation">招商合作</el-menu-item>
        <el-submenu index=6>
          <template slot="title">关于我们</template>
          <el-menu-item index="/company"
                        style="color:#909399;">公司介绍</el-menu-item>
          <el-menu-item index="/contact-us"
                        style="color:#909399;">联系我们</el-menu-item>
        </el-submenu>
      </el-menu>

    </div>
  </div>
</template>

<script>
export default {
  name: 'head-tab',
  data () {
    return {
    };
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    }
  },
}
</script>

<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100px;
  .main {
    margin: 0 auto;
    overflow: hidden;
    padding-top: 20px;
    width: 960px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo_pic {
      width: 136px;
      height: 52px;
      background: url("../../../assets/images/head_logo.png") no-repeat center;
      background-size: 100% 100%;
    }
    /deep/ .el-menu.el-menu--horizontal {
      border: none;
      width: 588px;
    }
    /deep/ .el-menu--horizontal > .el-menu-item {
      color: #909399 !important;
    }
    /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
      color: #909399 !important;
    }
    /deep/ .el-menu-item {
      color: #000 !important;
    }
  }
  .haoxiang {
    display: flex !important;
    justify-content: flex-start !important;
  }
  .item_hover {
    color: red;
  }
  /deep/ .el-menu--popup > div:has(.el-menu-item) :hover {
    background-color: red !important;
  }
}
</style>
