import Vue from "vue";
import VueRouter from "vue-router";
import nProgress from "nprogress";

Vue.use(VueRouter);

let routes = [];
// 匹配路由文件
const requireContext = require.context("./", true, /\.js$/);
// 自动加载路由文件
requireContext.keys().forEach(filename => {
  if (filename === "./index.js") return;
  const routerModule = requireContext(filename);
  // console.log(routerModule.default);
  routes = [...routes, ...(routerModule.default || routerModule)];
});

const router = new VueRouter({
  routes
});
// 动态加载路由
router.addRoutes([
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/views/home"),
    meta: {
      title: "尚盈期颐官网-尚盈车联智慧停车-数字运营管理"
    }
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/home"),
    meta: {
      title: "尚盈期颐官网-尚盈车联智慧停车-数字运营管理",
      keepAlive: true
    }
  },
  {
    path: "/success-demo",
    name: "success-demo",
    component: () => import("@/views/success-demo"),
    meta: {
      title: "尚盈期颐-成功案例",
      keepAlive: true
    }
  },
  {
    path: "/company",
    name: "company",
    component: () => import("@/views/company"),
    meta: {
      title: "尚盈期颐-公司介绍",
      keepAlive: true
    }
  },
  {
    path: "/business-cooperation",
    name: "business-cooperation",
    component: () => import("@/views/business-cooperation"),
    meta: {
      title: "尚盈期颐-招商合作-全国合伙人",
      keepAlive: true
    }
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("@/views/contact-us"),
    meta: {
      title: "尚盈期颐-联系我们",
      keepAlive: true
    }
  },
  {
    path: "/lxxp",
    name: "/lxxp",
    component: () => import("@/views/lxxp"),
    meta: {
      title: "数字经营-乐享小铺",
      keepAlive: true
    }
  },
  {
    path: "/symp",
    name: "/symp",
    component: () => import("@/views/symp"),
    meta: {
      title: "电商集采-尚盈名品",
      keepAlive: true
    }
  },
  {
    path: "/news",
    name: "/news",
    component: () => import("@/views/news"),
    meta: {
      title: "尚盈期颐-新闻中心",
      keepAlive: true
    }
  },
  {
    path: "/hxb",
    name: "hxb",
    component: () => import("@/views/hxb"),
    meta: {
      title: "智慧停车-尚盈车联●好享泊",
      keepAlive: true
    }
  },
  {
    path: "/hxj",
    name: "hxj",
    component: () => import("@/views/hxj"),
    meta: {
      title: "智慧停车-尚盈车联●好享驾",
      keepAlive: true
    }
  }
]);
// 导航守卫
router.beforeEach((to, from, next) => {
  //to 将要访问的路径
  //from 代表从哪个路径跳转而来
  //next 是一个函数，表示放行
  document.title = to.meta.title; //设置网页标题
  //next() 放行   next("/login")强制跳转到登录页面
  nProgress.start(); //开启进度条
  //地址为login，允许跳转到登录页
  // if (to.path === "/login") return next();

  //获取token
  // const tokenStr = localStorage.getItem("token");
  //如果token为空，则强制跳转到登录页
  // if (!tokenStr) return next("/login");
  next();
});
router.afterEach((to, from) => {
  nProgress.done();
});

export default router;
