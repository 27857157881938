import Vue from 'vue'
// 匹配组件目录下的文件
const requireContext = require.context(
  './global',
  true,
  /\.vue$/
)
// 自动注册为全局组件
requireContext.keys().forEach(filename => {
  const componentConfig = requireContext(filename)
  Vue.component(
    componentConfig.default.name || componentConfig.name,
    componentConfig.default || componentConfig
  )
})