<template>
  <div id="app">
    <head-tab v-show='isShow'></head-tab>

    <router-view />
    <foot-form v-show='isShow'></foot-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isShow: true
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {}
}
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";
</style>
