<template>
  <div class="container">
    <!-- <el-carousel indicator-position="inside"
                 height="486px">
      <el-carousel-item v-for="item in bannerList"
                        :key="item.url">
        <img class="banner_img"
             :src="item.url"
             alt="">
      </el-carousel-item>
    </el-carousel> -->
    <el-image class="banner_img"
              :src="src"
              alt=""
              lazy>
    </el-image>
    <div class="posDiv"
         v-if="info">
      <div class="text">
        <div class="title">{{ info.title }}</div>
        <div class="title_dsc">{{ info.dsc }}</div>
      </div>
      <div class="number">{{ info.number }}</div>
    </div>
    <div class="otherPosDiv"
         v-if="info1">
      <div class="text">
        <div class="dsc">{{ info1.dsc }}</div>
        <div class="title">{{ info1.title }}</div>
        <div v-if="info1.title == '尚盈名品'? true : false"
             class="symp">企业集采 · 员工福利 · 工会关怀</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'el-swiper',
  props: ['src', 'info', 'info1'],
  data () {
    return {

    };
  },
  computed: {
  },
  created () {

  },
  mounted () {

  },
  methods: {

  },
}
</script>

<style lang='scss' scoped>
.container {
  width: 100%;
  height: 486px;
  position: relative;
  .banner_img {
    width: 100%;
    min-width: 960px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .posDiv {
    position: relative;
    margin: 0 auto;
    width: 960px;
    height: 486px;
    color: #fff;
    .text {
      position: absolute;
      top: 98px;
      left: 0;
      .title {
        font-size: 44px;
      }
      .title_dsc {
        font-size: 20px;
        margin-top: 24px;
      }
    }
    .number {
      position: absolute;
      bottom: 30px;
      right: 0;
      font-size: 14px;
    }
  }
  .otherPosDiv {
    position: relative;
    margin: 0 auto;
    width: 960px;
    height: 486px;
    color: #fff;
    .text {
      padding-top: 88px;
      color: #ffffff;
      .dsc {
        font-size: 24px;
      }
      .title {
        font-size: 44px;
        margin-top: 20px;
      }
    }
    .symp {
      width: 280px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 18px;
      background-color: #fff;
      color: #e66856;
      margin-top: 20px;
    }
  }
}
</style>